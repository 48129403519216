
import DropdownContent from './DropdownContent.js';

function Projects() {

	return (

		<div>
			<h1>Professional</h1>

			<h2>Guidance-Navigation-Control for Autonomous Aircraft</h2>
			<h3>Python, C++, C#, ROS2 Foxy, Unity, ArduPilot, Raspberry Pi</h3>

			<DropdownContent title="More Info">

				<h4>Description</h4>

				<p>
					Development and testing were implemented with the ROS2 Foxy framework.
					Simulations were run inside the ArduPilot SITL dynamics engine and visualized with Unity and QGroundControl.
					The code was ported onto live drones via Raspberry Pi companion computers.
					High-speed, multi-agent scenarios were tested at an airfield.
				</p>

				<p>
					The above work contributed to the research collected for Michael Mardikes' master's thesis, <a className='dropdown-content-source' href='https://www.proquest.com/openview/8c462a7276e3c440b7d6eb7d4d4be617/1.pdf?cbl=18750&diss=y&pq-origsite=gscholar' target="_blank" rel="noopener noreferrer"><i>Development of Guidance and Navigation Strategies for an Autonomous Unmanned Aircraft System</i></a>.
				</p>

			</DropdownContent>

			<h2>Assembly Line Takt-Time System</h2>
			<h3>C#/.NET, WinForms, Arduino Design & Programming</h3>

			<DropdownContent title="More Info">

				<h4>Description</h4>

				<p>
					A collection of software programs that complemented the Vorne
					XL810-1 productivity appliance in the final assembly department. The primary applications were the Leader and Operator programs.
				</p>

				<p>
					The Leader program allowed the team leader of the assembly line to enter their efficiency goal for particular product.
					The program would then interface with a Rest API to retrieve the required information to compute the effective takt-time
					needed to achieve their goal.
				</p>

				<p>
					The Operator program synchronized with the Leader program over LAN to keep each operator up to speed
					with the takt-time. Additionally, the operator was able to view the performance of their assembly lines in an hour-by-hour scoreboard
					format. The scoreboard feature encouraged healthy competition between the assembly lines which increased productivity.
				</p>

				<p>
					Additionally, LED lights were added to the assembly lines to indicate the their status (production, break, down, etc.) from far away and to temporarily flash off
					when it was time to ship a product off of the line. These lights were controlled by an Arduino, which received messages from the Operator program
					via a serial port.
				</p>
			
			</DropdownContent>

			<br/>

			<h1>Academic</h1>

			<h2>SOS Game</h2>
			<h3>C#, .NET, WinForms, Agile/Scrum Methodologies (User Stories, Acceptance Criteria, etc.), UML, Testing</h3>

			<DropdownContent title="More Info">

				<h4>Description</h4>

				<p>An application that runs the SOS game. It was developed in five sprints using the Scrum development process.</p>

				<p>
					In the game, there is a board with n x n squares arranged in a grid.
					Players alternate turns, placing either an "S" or an "O" on an empty square.
					The goal for each player is to create a straight sequence of S-O-S among connected squares, either horizontally, vertically, or diagonally.
					To keep track of which player made which SOS sequence, a line in the player's color (either blue or red) is drawn for each completed SOS.
				</p>

				<p>
					There are two game modes: simple and general.
					In a simple game, the player who successfully forms an SOS sequence first is the winner.
					If the board is completely filled and no SOS has been created, the game ends in a draw.
				</p>

				<p>
					A general game continues until all squares on the board have been filled.
					The player with the most SOS sequences is the winner.
					If both players have the same number of SOS sequences, the game is a draw.
					If a player creates an SOS, they get to take another turn immediately and continue to do so until they do not create an SOS on their turn.
					If a player does not create an SOS, turns alternate between players after each move.
				</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/sosgame' target="_blank" rel="noopener noreferrer">Source Code</a>

				<h4>Sprint 1</h4>

				<p>User stories and acceptance criteria for a human player to play a simple or general SOS game against another human player.</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/sosgame/blob/main/artifacts/sprint-1-report.pdf' target="_blank" rel="noopener noreferrer">Sprint 1 Report</a>

				<h4>Sprint 2</h4>

				<p>Game initialization and S/O placement for human players.</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/sosgame/blob/main/artifacts/sprint-2-report.pdf' target="_blank" rel="noopener noreferrer">Sprint 2 Report</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='https://youtu.be/KyzlwQHWO70' target="_blank" rel="noopener noreferrer">Sprint 2 Demo</a>
				
				<h4>Sprint 3</h4>
				<p>Complete simple/general games.</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/sosgame/blob/main/artifacts/sprint-3-report.pdf' target="_blank" rel="noopener noreferrer">Sprint 3 Report</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='https://youtu.be/Ll_Q_A0j9fc' target="_blank" rel="noopener noreferrer">Sprint 3 Demo</a>

				<h4>Sprint 4</h4>
				<p>Computer opponent, including user stories/acceptance criteria and implementation.</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/sosgame/blob/main/artifacts/sprint-4-report.pdf' target="_blank" rel="noopener noreferrer">Sprint 4 Report</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='https://youtu.be/CHrN7hRTuk4' target="_blank" rel="noopener noreferrer">Sprint 4 Demo</a>

				<h4>Sprint 5</h4>
				<p>Record and replay (including user stories, acceptance criteria, and implementation), design review.</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/sosgame/blob/main/artifacts/sprint-5-report.pdf' target="_blank" rel="noopener noreferrer">Sprint 5 Report</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='https://youtu.be/HB9f47JSXfI' target="_blank" rel="noopener noreferrer">Sprint 5 Demo</a>


			</DropdownContent>


			<h2>Full-Stack Meme Sharing Android App</h2>
			<h3>Java, Android Studio, ExpressJS Server, MySQL Queries & Stored Procedures</h3>

			<DropdownContent title="More Info">

				<h4>Description</h4>

				<p>A minimal full-stack android application that implements user account creation and authentication, and the liking and commenting of posts.</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/Meme-Stream-Android-App' target="_blank" rel="noopener noreferrer">Front-End (App) Source Code</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/Meme-Stream-Backend' target="_blank" rel="noopener noreferrer">Back-End Source Code</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='https://www.youtube.com/watch?v=wYqnUEr_PHs' target="_blank" rel="noopener noreferrer">Voiceover Demo</a>

			</DropdownContent>

			<h2>Online Poker Database Design</h2>
			<h3>ER + Schema Diagrams, ExpressJS Server, MySQL Queries & Stored Procedures</h3>

			<DropdownContent title="More Info">

				<h4>Description</h4>

				<p>A backend system that allows users to buy into a variety of virtual poker tables and play poker with
				one another remotely.</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/Texas-Holdem-MySQL' target="_blank" rel="noopener noreferrer">Source Code</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='./poker-report.pdf' target="_blank" rel="noopener noreferrer">Project Report</a>
				
			</DropdownContent>

			<h2>Multi-Elevator System Simulator</h2>
			<h3>C, CMake, UML, Algorithm Analysis</h3>

			<DropdownContent title="More Info">

				<h4>Description</h4>

				<p>A program that simulates a multi-elevator system. An algorithm was written to minimize the amount of waiting
				time for each client. The optimization algorithm simply chooses the closest elevator to the client, depending on
				distance between the client and the elevators, and which direction the elevators are headed.</p>


				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/Multi-Elevator-System-Simulator' target="_blank" rel="noopener noreferrer">Source Code</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='./elevator-report.pdf' target="_blank" rel="noopener noreferrer">Project Report</a>

			</DropdownContent>

			<h2>BASIC Parser</h2>
			<h3>Racket, RackUnit</h3>

			<DropdownContent title="More Info">

				<h4>Description</h4>

				<p>A recursive-descent parser for a simplified version of the early BASIC programming language.</p>


				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/BASIC-parser' target="_blank" rel="noopener noreferrer">Source Code</a>

			</DropdownContent>


			<h2>Digital Lock from Logic-Gates</h2>
			<h3>State Diagrams, K-Maps, Implication Tables, Logisim</h3>

			<DropdownContent title="More Info">

				<h4>Description</h4>

				<p>A digital lock that unlocks once it recognizes a correct sequence of button presses
				from two buttons.</p>

				<a className='dropdown-content-source' href='https://github.com/jonathanbenson/Digital-Lock-Design' target="_blank" rel="noopener noreferrer">Source Code</a>
				<br/>
				<br/>
				<a className='dropdown-content-source' href='./lock-report.pdf' target="_blank" rel="noopener noreferrer">Project Report</a>
				
			</DropdownContent>


		</div>

	);
}


export default Projects;