
function NotFound() {

	return (

		<div>
			<h1>You are lost!</h1>
			<p>The page you are looking for may be located in the navigation menu.</p>
			<p>...if the menu is collapsed, it can be toggled with the '☰' icon (trigram) in the top left.</p>
		</div>
	);
}

export default NotFound;