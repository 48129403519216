

import "../styles/Footer.css";


function Footer() {

	let gitLabLink = 'https://gitlab.com/jonathanbenson/portfolio';

	return (

		<div className="footer"><a href={gitLabLink} className="lab_link" target="_blank" rel="noopener noreferrer">Website</a> developed with React and Gitlab CI/CD.</div>

	);
}

export default Footer;