
import {useState} from 'react';

import '../styles/DropdownContent.css';

function DropdownContent(props) {

	const [isCollapsed, setIsCollapsed] = useState(true);

	const handleOnClick = () => {
		// When the title is clicked...
		// If the content is hidden (collapsed), then reveal it
		// If the content is revealed, then hide it

		if (isCollapsed)
			setIsCollapsed(false);
		else
			setIsCollapsed(true);

	};

	return (

		<div>

			<i><h3 className='clickable' onClick={handleOnClick}>{isCollapsed ? '+' : '-'} {props.title}</h3></i>

			{
				isCollapsed ?
				''
				:
				<div id={props.contentContainerId} className='content-container'>
					{props.children}
				</div>
			}

		</div>

	);

}


export default DropdownContent;