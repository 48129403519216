
import DropdownContent from './DropdownContent.js';
import {Link} from "react-router-dom";

function Experience() {

	return (

		<div>

			<h1>Software Developer</h1>

			<h2>City of Overland Park</h2>

			<h3>May 2023 - Present</h3>

			<DropdownContent title='More Info'>
				<h4>Gist</h4>
				<p>
					I lead all software development projects managed by the city's IT department.
				</p>

				<h4>Development Process & Duties</h4>
				<ul>
					<li>Patch and extend the functionality of legacy software projects and websites.</li>
					<li>Develop and auto-test in containerized environments to replicate production.</li>
					<li>Construct continuous integration pipelines to minimize downtime of public services during maintenance.</li>
					<li>In charge of database administration on mission-critical SQL Server databases.</li>
					<li>Scripting for workplace automation services.</li>
					<li>Scripting for ETL reporting services.</li>
				</ul>

				<h4>Relevant Languages & Tools</h4>
				<ul>
					<li>C#</li>
					<li>Microsoft .NET</li>
					<li>SQL Server</li>
					<li>Entity Framework Core</li>
					<li>Linq</li>
					<li>VIM</li>
					<li>Docker</li>
					<li>PowerShell</li>
					<li>Python</li>
					<li>Flask</li>
					<li>Node.js</li>
					<li>Embedded Javascript (EJS)</li>
				</ul>
			</DropdownContent>



			<br/>

			<h1>Research Assistant</h1>

			<h2>UMKC / Missouri Institute for Defense & Energy</h2>

			<h3>June 2022 - March 2023</h3>

			<DropdownContent title='More Info'>
				<h4>Gist</h4>
				<p>
					I assisted the aircraft design team in developing guidance and navigation software for their autonomous aircraft.
				</p>

				<h4>Development Process & Duties</h4>
				<ul>
					<li>Lead the development of guidance and navigation software for autonomous aircraft.</li>
					<li>Unit, integration, and end-end testing in the ROS2 programming environment with Python/C++.</li>
					<li>Simulation performed with ArduPilot SITL and the Unity engine.</li>
					<li>Deployment and live testing on drones with Raspberry PI's and RFD900x modems.</li>
				</ul>

				<h4>Relevant Languages & Tools</h4>
				<ul>
					<li>Python</li>
					<li>C++</li>
					<li>C#</li>
					<li>Robot Operating System (ROS)</li>
					<li>Unity</li>
					<li>Raspberry Pi</li>
					<li>Linux (Debian)</li>
					<li>Bash Scripting</li>
					<li>CAD Design with Solidworks & OpenSCAD</li>
					<li>3d Printing</li>
				</ul>
			</DropdownContent>



			<br/>

			<h1>Manufacturing Engineer</h1>

			<h2>Seats Inc.</h2>

			<h3>June 2020 - August 2021</h3>

			<DropdownContent title='More Info'>

				<h4>Gist</h4>
				<p>
					My clerical duties consisted of maintaining manufacturing routings and BOMs, conducting time studies,
					writing digital work instructions, and troubleshooting various robot/computer related issues out on the shop floor.
					The rest of my time was spent working on continuous improvment projects which included the
					development of several software applications that the final assembly department utilized to increase the efficiency of their
					assembly lines.
				</p>

				<h4>Duties</h4>
				<ul>
					<li>Designed and critiqued manufacturing processes to maximize efficiency and reduce cost.</li>
					<li>Conducted time studies and prepared capital equipment justifications for puchases.</li>
					<li>Maintained manufacturing process routings and digital work instructions.</li>
					<li>Responsible for the proper functioning of six robotic-arm, kill-switch testers.</li>
					<li>Deployed several continuous-improvement software projects into live production.</li>
					<li>See the <b>Assembly Line Takt-Time System</b> section on the <Link to="/projects">Projects</Link> page for more information.</li>
				</ul>

				<h4>Relevant Languages & Tools</h4>
				<ul>
					<li>Python</li>
					<li>C</li>
					<li>C#</li>
					<li>.NET</li>
					<li>WinForms</li>
					<li>AutoCad</li>
					<li>Arduino Design & Programming</li>
				</ul>

			</DropdownContent>
			

			<br/>

			<h1>Manufacturing Team Leader</h1>

			<h2>Seats Inc.</h2>

			<h3>October 2019 - June 2020</h3>

			<DropdownContent title='More Info'>
				<h4>Gist</h4>
				<p>
					I led a team of operators on 1-3 single-pass assembly lines to help foster a safe and enjoyable work environment, identify bottlenecks,
					maintain quality standards, and manage logistics.
				</p>

				<h4>Duties</h4>
				<ul>
					<li>Planned goals and established a common-vision for the assembly teams.</li>
					<li>Performed first-piece and routine quality inspections on all orders.</li>
					<li>Maintained the takt-time of the assembly lines by resolving bottlenecks.</li>
					<li>Assited with the planning of production schedules.</li>
					<li>Trained and all new final assembly operators, and emerging team leaders.</li>
				</ul>
			</DropdownContent>




		</div>

	);
}


export default Experience;