

import DropdownContent from './DropdownContent.js';

function Education() {

	const courses = [

		['ANCH 150','Computing/Eng-Society','The purpose of ANCH 101 is to help new students make a successful transition to the University of Missouri-Kansas City, both academically and personally. This course aims to help students develop and apply critical thinking skills (Interdisciplinary and Innovative Thinking and Valuing and Reasoning), engage in the curricular and co-curricular life of the university, articulate to students the expectations of the University and its faculty, understand the value of a liberal education in the 21st century, and continue to clarify their purpose, meaning, and direction.'],
		['COMP-SCI 101','Problm Slvng & Prgmg I','Problem solving, algorithms, and program design. Use of structured programming, lists, control structures, recursion, objects and files in Python. Introduction to graphical interface programming. Coding, testing and debugging using a modern development environment. '],
		['COMP-SCI 101L','Problem Solving/Program Lab I','Programming exercises and demonstrations to reinforce concepts learned in COMP-SCI 101 and provide additional practice in Python programming.'],
		['DISC 100','Discourse I','“Discourse” refers to the language, images, styles, genres, behaviors and other forms of communication used by specific social and professional groups. The techniques of discourse analysis and language awareness taught in this course will enable you to position yourself socially and professionally, helping you understand the discourse conventions, reasoning, and "commonsense" assumptions that create and define academic, political, professional, and other discourse formations and communities. Students will produce, perform, and analyze college-level, oral and written texts; and they will learn how written and oral performances function together in specific discourse communities. '],
		['MATH 120','Precalculus','This course combines the content of the courses MATH 110 (Precalculus Algebra) and MATH 125 (Trigonometry), to serve as a complete prerequisite for calculus courses as required in STEM fields and other areas such as advanced finance. Topics include the foundational concepts of functions, use and analysis of linear, exponential, polynomial, and rational functions, and solving systems of equations and inequalities, and trigonometric functions and their relationships to geometry and periodic phenomena.'],
		['ANCH 214','European Cultures, Hist & Idea','This interdisciplinary course will explore the cultures, histories, and ideas of a particular region of Europe. Students will be exposed to a wide range of disciplinary approaches to this topic and learn how to engage critically in an interdisciplinary dialogue within this field.'],
		['CNSVTY 120','Music Appreciation','Designed for the general University student with little or no music background (non-music majors). An emphasis on the basic elements of music and the historical and stylistic periods, illustrated by examples from different genre, such as instrumental and vocal ensembles, large and small, solo literature for voice and instruments, and dance.'],
		['COMP-SCI 191','Discrete Structures I','Mathematical logic, sets, relations, functions, mathematical induction, algebraic structures with emphasis on computing applications. '],
		['DISC 200','Discourse II','Students will produce, perform, and analyze college-level, oral and written texts that are based on sustained academic research. Students will continue to develop their understanding of discourse analysis and language awareness in the context of a range of discursive forms. Students will interpret and synthesize college-level scholarship that addresses how diverse discourse communities define, evaluate, and transform individual, institutional, and cultural identities. '],
		['MATH 210','Calculus I','This course introduces the theory of calculus for functions of one variable, with focus on rational, trigonometric, exponential and logarithmic functions. The central object of study is the concept of derivative of a function and its applications, and is followed by an introduction to the theory of integration, with applications to area and rectilinear motion. '],
		['COMP-SCI 201L','Problem Solving/Program Lab II','Programming exercises and demonstrations to reinforce concepts learned in COMP-SCI 201R and provide additional practice in C++ programming. '],
		['COMP-SCI 201R','Problm Slvng & Prgmg II','Problem solving and programming using classes and objects. Algorithm efficiency, abstract data types, searching and sorting, templates, pointers, linked lists, stacks and queues implemented in C++. '],
		['COMP-SCI 303','Data Structures','Linear and hierarchical data structures, including stacks, queues, lists, trees, priority queues, advanced tree structures, hashing tables, dictionaries and disjoint-set. Abstractions and strategies for efficient implementations will be discussed. Linear and hierarchical algorithms will be studied as well as recursion and various searching and sorting algorithms. Programming concepts include Object Orientation, concurrency and parallel programming. Several in-depth projects in C++ will be required. '],
		['MATH 220','Calculus II','This is a second course in the calculus of functions of one variable. It continues to develop the theory of integration introduced in the first course, addresses physical applications, and covers the topic of infinite series, including power series. The theory of calculus is further studied in the context of parametric equations and polar coordinates.'],
		['PHYSICS 240','Physics for Scientists and Eng','Introduction to mechanics, wave motion and sound and heat and thermodynamics. '],
		['COMP-SCI 291','Discrete Structures II','Recurrence relations and their use in the analysis of algorithms. Graphs, trees, and network flow models. Introduction to Finite state machines, grammars, and automata. '],
		['MATH 300','Linear Algebra I','This course introduces a branch of algebra developed from the theory of finding simultaneous solutions of a collection of linear equations, emphasizing fundamental concepts, calculations, and applications. Topics include matrix algebra, real vector spaces, linear transformations, determinants, the study of eigenvalues and eigenvectors, and the exploration of orthogonality and other applications. '],
		['PHYSICS 250','Physics for Scientists and Eng','Introduction to electricity and magnetism, light and optics and modern physics. Four hours lecture and two hours laboratory per week. '],
		['COMP-SCI 404','Intro to Algorithms & Complex','A rigorous review of asymptotic analysis techniques and algorithms: from design strategy (such as greedy, divide-and-conquer, and dynamic programming) to problem areas (such as searching, sorting, shortest path, spanning trees, transitive closures, and other graph algorithms, string algorithms) arriving at classical algorithms with supporting data structures for efficient implementation. Throughout, the asymptotic complexity is studied in worst case, best case, and average case for time and/or space, using appropriate analysis techniques (recurrence relations, amortization). Introduction to the basic concepts of complexity theory and NP-complete theory.'],
		['ANCH 308','Ethical Issues-Comp/Eng','Societal and ethical obligations of computer science, IT, and electrical/computer engineering practice. Topics include ethical obligations of professional practice, electronic privacy, intellectual property, software and system security and reliability, and whistle-blowing. This course teaches the principles of ethical analysis and how technology, law, and ethics interact in society, to help the graduate confront and deal with the ethical challenges that arise in professional practice. '],
		['COMP-SCI 281R','Intro Cmptr Architecture & Org','Digital Logic and Data Representation, process architecture and instruction sequencing, memory hierarchy and bus-interfaces and functional organization. '],
		['DISC 300','Discourse III','Students will put the knowledge and skills learned in Discourse I and II into practical use by engaging in a service-learning project that is interdisciplinary and intercultural. Students will use strategies of critical discourse analysis and critical language awareness to target the appropriate audience/recipients for their research project, to develop innovative and rhetorically effective texts, and to reflect on their project’s purpose, methods, and consequences. '],
		['STAT 235','Elementary Statistics','An introduction to descriptive and inferential statistics. Organization and presentation of data, averages and variations, elementary probability, random variables, special discrete distributions, normal distributions, sampling distributions, point estimation, confidence intervals, and hypothesis testing. '],
		['COMP-SCI 349','Java Program Application','The course covers the syntax and semantics of the Java programming language along with the use of essential class libraries. These topics will be taught in the context of application development. Students will learn how to write small to medium sized Java applications and applets. Specific topics covered include: essential classes in the Java API, interfaces, inheritance, exceptions, graphical user interface components, layout managers, events, I/O classes, Applets, data base access, and multithreading. Other topics will be covered as time permits. '],
		['COMP-SCI 431','Intro Operating Systems','This course covers concurrency and control of asynchronous processes, deadlocks, memory management, processor and disk scheduling, x86 assembly language, parallel processing, security, protection, and file system organization in operating systems. '],
		['COMP-SCI 470','In Database Mgmt Systems','This course covers database architecture, data independence, schema, Entity-Relationship (ER) and relational database modeling, relational algebra and calculus, SQL, file organization, relational database design, physical database organization, query processing and optimization, transaction structure and execution, concurrency control mechanisms, database recovery, and database security. '],
		['COMP-SCI 490','Video Game Creation','Selected topics in specific areas of computer science. May be repeated for credit when the topic varies. '],
		['COMP-SCI 490WD','Web Development','Selected topics in specific areas of computer science. May be repeated for credit when the topic varies. '],
		['MIS 202','Computer Applications In Management','A comprehensive coverage of computer tools for personal productivity in a management context, including spreadsheets and databases. '],
		['CHEM 206','Human Nutrition','Introduction to nutrition for health and wellness and the use of chemical energy in the breakdown and synthesis of biomolecules. Nutrition as it applies to a variety of life situations from infancy to older adults. Learning encompasses elements of anatomy and physiology related to nutrition and health.'],
		['HISTORY 101','U.S. History to 1877','This course offers a broad survey of American history up to 1877.'],
		['COMP-SCI 394R','Applied Probability','Basic concepts of probability theory. Counting and measuring. Probability, conditional probability and independence. Discrete, continuous, joint random variables. Functions of random variables. Sums of independent random variables and transform methods. Random number generation and random event generation. Law of large numbers, central limit theorem, inequalities. Their applications to computer science and electrical and computer engineering areas are stressed.'],
		['COMP-SCI 449','Foundations of Software Engineering','An introduction to the fundamental principles and practices of software engineering. Students will learn a systematic and disciplined process for developing software and apply it to a small project. The focus will be on the agile development process, software requirements, software design, and unit testing.']


	];

	courses.sort((a, b) => (a[0] > b[0]) ? 1 : -1);


	return (

		<div>

			<h1>College</h1>

			<h2>University of Missouri-Kansas City</h2>

			<h3>BS Computer Science + Math Minor</h3>

			<u><h3>3.99 GPA</h3></u>

			<DropdownContent title='Courses'>

				<h4>Undergraduate Courses</h4>

				<p>Behold! The following courses were completed during my undergraduate. Their descriptions were sourced from when they were completed.</p>

				<table>

					<tbody>
						<tr>
							<td className='course-data-heading course-data-field'>ID</td>
							<td className='course-data-heading course-data-field'>Name</td>
							<td className='course-data-heading course-data-field'>Description</td>
						</tr>
					</tbody>

					{
						courses.map(courseData => (

							<tbody>
								<tr>

								{
									courseData.map(data => <td className='course-data-field'>{data}</td>)
								}

								</tr>
							</tbody>

						))
					}
				</table>

			</DropdownContent>

			<DropdownContent title='More Info'>

				<h4>Summary</h4>

				<p>
					My first two years were completed from Fall 2018 to Spring 2020.
					I then forwent pandemic-induced online classes for a year of full-time work at Seats Inc. as a manufacturing engineer.
				</p>
				<p>I came back to school in Fall of 2021 after classes returned to in-person lectures. I graduated in Fall 2023.</p>
					

			</DropdownContent>


			<h2>Awards</h2>



			<DropdownContent title='Paul H. Henson Scholarship Award'>

				<h4>Description</h4>

				<p><i>Awarded to an undergraduate Computer Science or Information Technology major.</i></p>

				<h4>Requirements</h4>

				<ul>
					<li>Enrolled full-time (12+ credit hours) per semester</li>
					<li>Maintain a 3.0+ GPA</li>
					<li>Enrolled in a degree program in the School of Computing & Engineering at UMKC, Computer Science or Information Technology</li>
				</ul>

				<h4>Semesters</h4>

				<ul>
					<li>Fall 2021</li>
					<li>Spring 2022</li>
				</ul>

				<a className='dropdown-content-source' href='https://umkc.academicworks.com/opportunities/10041' target="_blank" rel="noopener noreferrer">Source</a>

			</DropdownContent>

			<DropdownContent title="Dean's List">

				<h4>Description</h4>

				<p><i>The Dean’s List is a unit-based student-centric recognition of excellent academic performance.  Students must complete a minimum full-time program of 12 graded hours to qualify for the dean’s list.</i></p>

				<h4>Semesters</h4>

				<ul>
					<li>Fall 2018</li>
					<li>Spring & Fall 2019</li>
					<li>Spring 2020</li>
					<li>Fall 2021</li>
					<li>Spring & Fall 2022</li>
					<li>Spring & Fall 2023</li>
				</ul>

				<a className='dropdown-content-source' href='https://www.umkc.edu/registrar/academic-programs/graduation/deans-list-honors.html' target="_blank" rel="noopener noreferrer">Source</a>

			</DropdownContent>

			<br/>

			<h1>High School</h1>

			<h2>Rockhurst High School</h2>

			<DropdownContent title='More Info'>

				<h4>Music</h4>

				<p>Played the electric-bass guitar for the jazz-band, pep-band, and pit-orchestra.</p>

				<p>Also played the bass drum in the school's drumline.</p>

				<h4>Sports</h4>

				<p>Ran cross country and track. Served as a leadership role on the junior-varsity teams.</p>

				<h4>Clubs</h4>

				<p>Maintained membership in the Astronomy Club. Helped plan and setup star-parties; where students
				gathered on the baseball field once every semester at night to view celestial objects.</p>

				<a className='dropdown-content-source' href='https://www.rockhursths.edu' target="_blank" rel="noopener noreferrer">Website</a>

			</DropdownContent>


		</div>

	);
}


export default Education;