import '../styles/App.css';

import MainNav from "./MainNav.js";
import LeftPanel from "./LeftPanel.js";
import Footer from "./Footer.js";

import {Outlet} from "react-router-dom";

function App() {

    return (

        <div className="app">

            <MainNav />

            <div className="mid_section">
                
                <LeftPanel />

                <div className="content" id="_content">
                    <Outlet />
                </div>

            </div>

            <Footer />

        </div>
    );
}

export default App;
