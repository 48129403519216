import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './components/App.js';
import Bio from './components/Bio.js';
import Contact from './components/Contact.js';
import Education from './components/Education.js';
import Experience from './components/Experience.js';
import Projects from './components/Projects.js';
import NotFound from './components/NotFound.js';
import { BrowserRouter, Routes, Route } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

// Palette: https://coolors.co/palette/f8f9fa-e9ecef-dee2e6-ced4da-adb5bd-6c757d-495057-343a40-212529
// ...and https://coolors.co/palette/f8f9fb-e1ecf7-aecbeb-83b0e1-71a5de

root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
          <Route path='/' element={<App />}>
            <Route index element={<Bio />} />
            <Route path='bio' element={<Bio />} />
            <Route path='contact' element={<Contact />} />
            <Route path='education' element={<Education />} />
            <Route path='experience' element={<Experience />} />
            <Route path='projects' element={<Projects />} />
            <Route path='*' element={<NotFound />} />
          </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);



