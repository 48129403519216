

import "../styles/MainNav.css"

function MainNav() {


    const menu_button_on_click = () => {
        // Callback for the menu button
        // Reveals and hides the left panel (navigation options)

        let lp = document.getElementById("_left_panel");
        let c = document.getElementById("_content");

        if (lp.style.display === 'flex') {
            // If the left panel is revealed
            // Then hide the left panel

            lp.style.display = 'none';

            if (window.screen.width <= 500) {
                // If the user is on mobile
                // Then reveal the content

                c.style.display = 'flex';
            }
        }
        else {
            // If the left panel is hidden
            // then reveal the left panel

            lp.style.display = 'flex';

            if (window.screen.width <= 500) {
                // If the user is on mobile
                // then hide the main content
                
                c.style.display = 'none';
            }
        }

    }

	return (

        <nav className="main_nav">

            <div className="menu_button" onClick={menu_button_on_click}>☰</div>

        </nav>

	);
}


export default MainNav;