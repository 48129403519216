

function Contact() {

	return (

		<div>
			<h1>Contact</h1>

			<h2>Email</h2>
			<p>email@bensonjonathan.com</p>
		</div>

	);
}


export default Contact;