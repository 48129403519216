

import {useEffect} from 'react';

import '../styles/Pages.css';

function Bio() {

	useEffect(() => {
		// Call every time the page loads

		let bb = document.getElementById('_bio-body');

		if (window.screen.width <= 500) {
			// If the window width is below 500px (mobile)
			// Then center the contents of the bio content

			bb.style.flexDirection = 'column';
			bb.style.alignItems = 'center';

		}
		else {
			// If the window width is above 500px
			// Then align everything left
			
			bb.style.flexDirection = 'row';
			bb.style.alignItems = 'left';
		}

	}, []);

	return (

		<div>
			<h1>Bio</h1>


			<div className='bio-body' id='_bio-body'>

				<figure>
					<img className='face' src={`${process.env.PUBLIC_URL}/face.jpeg`}
						alt='Professional Headshot'/>

					<figcaption>Jonathan Benson</figcaption>

					<figcaption><a className='dropdown-content-source' href='https://www.linkedin.com/in/jonathan-benson-b7b664246/' target="_blank" rel="noopener noreferrer">LinkedIn</a></figcaption>


				</figure>

				<div>
					
					<p>
						Software Developer for the City of Overland Park
					</p>
						
					<p>
						BS in Computer Science and Math Minor from the University of Missouri-Kansas City.
					</p>
					<p>
						Experience developing software applied to city government, manufacturing engineering, and graduate research.
					</p>

				</div>

			</div>
		</div>

	);
}


export default Bio;