

import "../styles/LeftPanel.css";
import {Link} from "react-router-dom";
import {useState, useEffect} from "react";

function LeftPanel() {

	const [isHidden, setIsHidden] = useState(false);

	useEffect(() => {
		// Called every time the page loads

		let lp = document.getElementById("_left_panel");

		if (window.screen.width <= 500) {
			// If the user is on mobile...
			// Then hide the left panel

			setIsHidden(true);
			lp.className = 'left_panel_mobile'
		}
		else {
			// If the user is on desktop
			// then reveal the left panel

			setIsHidden(false);
			lp.className = 'left_panel'
		}

	}, []);

	const option_on_click = () => {
		// Function called every time a menu option is clicked

		let lp = document.getElementById("_left_panel");
		let c = document.getElementById("_content");

		if (window.screen.width <= 500) {
			// If the user is on mobile
			// Then hide the left panel again (or else it will block the main content)

			lp.style.display = 'none';
			c.style.display = 'flex';
		}

	}

	return (

		<div className="left_panel" id="_left_panel" style={{display: isHidden ? 'none' : 'flex' }}>

			<div className="navigation">Menu</div>
			
			<Link to="/bio" style={{ textDecoration: 'none', color: 'white' }}><div className="option clickable" onClick={option_on_click}>Bio</div></Link>
			<Link to="/education" style={{ textDecoration: 'none', color: 'white' }}><div className="option clickable" onClick={option_on_click}>Education</div></Link>
			<Link to="/experience" style={{ textDecoration: 'none', color: 'white' }}><div className="option clickable" onClick={option_on_click}>Experience</div></Link>
			<Link to="/projects" style={{ textDecoration: 'none', color: 'white' }}><div className="option clickable" onClick={option_on_click}>Projects</div></Link>
			<Link to="/contact" style={{ textDecoration: 'none', color: 'white' }}><div className="option clickable" onClick={option_on_click}>Contact</div></Link>

		</div>

	);
}


export default LeftPanel;
